// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react'
import { ProductHero } from '@ggs/components/ecomm'
import { GridContainer, Layout } from '@ggs/components/layout'
import { ImageCta, TabsWithMobileAccordions } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { breakpoints, colors } from '@ggs/styles'
import Box from '@mui/material/Box'
import { graphql } from 'gatsby'
import { get, uniqueId } from 'lodash'
import { ComponentSettings } from '@ggs/gatsby/components/Component'
import { useBackToLink } from './useBackToLink'
import { useNavToProductVariant, useProductData } from './useProductData'
import { ProductJsonLd } from 'gatsby-plugin-next-seo'
import { productValueQuantityGTM } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { categoryObj } from '@ggs/components/hooks/ViewProductListObserver'

const ProductGrid = React.lazy(() => import('@ggs/components/paragraphs/ProductGrid/ProductGrid'))
const Testimonials = React.lazy(() => import('@ggs/components/paragraphs/Testimonials'))
const Reimbursement = React.lazy(() =>
  import('@ggs/components/paragraphs/Reimbursement/Reimbursement')
)

const useFireViewProduct = ({ skuPrice, activeOption, ...product }) => {
  const { fireViewProduct } = useCheckoutAnalytics()
  const [reportedItem, setReportedItem] = useState(null)
  const catObj = categoryObj(product)
  useEffect(() => {
    setReportedItem((currentValue) => {
      if (skuPrice && activeOption?.id) {
        if (currentValue !== activeOption.id) {
          const gtmItem = productValueQuantityGTM({
            skuPrice,
            productOption: activeOption,
            quantity: 1,
          })
          fireViewProduct({
            items: [{ ...gtmItem.gtmProduct, ...catObj }],
            value: gtmItem.value,
            currency: gtmItem.currency,
          })
          return activeOption.id
        }
        return null
      } else {
        return currentValue
      }
    })
  }, [skuPrice, activeOption])
}

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const Product = (props) => {
  const pageContext = props?.pageContext || {}
  const { t } = useI18n()
  const hideOnMobile = true
  const {
    relatedProducts,
    productDetails,
    reimbursement,
    footerCta,
    testimonials,
    layoutMeta,
    /** @type {import('@ggs/types').Product} */ ...data
  } = pageContext?.data || {}
  const listing = layoutMeta?.listing || {}
  data.url = data.alias
  const { BackToProducts } = useBackToLink()
  const productData = useProductData({ data: pageContext?.data, location: props?.location })

  const { productSearchParams, navToProductVariant } = useNavToProductVariant(productData)

  const {
    url,
    name,
    subtitle,
    includedItems = [],
    productOptions = [],
    productCategories,
    frequentlyBought,
    optionsLabel,
    optionsFooter,
    hasPricing,
    purchaseOption,
    activeOption,
    productImages = null,
    changeOption,
    skuPrice,
    productPrice,
    defaultOption,
  } = productData

  const price = productPrice?.skuPrice
  const metaImageSrc = get(
    data,
    'listingImage.styles.productCard.childImageSharp.gatsbyImageData.images.fallback.src'
  )

  useFireViewProduct(productData)
  //
  // useEffect(() => {
  //   // Update meta data for SEO
  //   // const metaImageSrc = get(
  //   //   data,
  //   //   'listingImage.styles.productCard.childImageSharp.gatsbyImageData.images.fallback.src'
  //   // )
  //   // transformMetaData(pageContext, {
  //   //   image_src: metaImageSrc,
  //   //   'og:image': metaImageSrc,
  //   //   graphData: {
  //   //     '["@graph"][0].image.url': metaImageSrc,
  //   //     '["@graph"][0].category': data?.productCategories?.map((c) => c.name)?.join(', '),
  //   //   },
  //   // })
  // }, [pageContext])

  return (
    <Layout
      className="product"
      site={props?.queries?.site}
      pageContext={{ ...pageContext, data: { ...pageContext?.data, title: pageContext.data.name } }}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
      productSEO={
        price?.amount_float ? (
          <ProductJsonLd
            name={name}
            sku={
              skuPrice?.sku_code
              // TODO: filter
              ?? productOptions[0]?.sku?.[0]?.sku
            }
            images={metaImageSrc}
            description={subtitle}
            offers={{
              price: price?.amount_float.toString() ?? '99',
              priceCurrency: price?.currency_code.toString() ?? 'CAD',
            }}
          />
        ) : null
      }
    >
      <Box
        sx={{
          maxWidth: breakpoints.xl,
          m: '0 auto',
          px: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <Box
          className="product__content"
          sx={{
            mt: 0,
            mx: 'auto',
            mb: 10,
            px: {
              xs: 0,
              lg: 6,
            },
            '> .grid-container:not(.reimbursement) > div': {
              px: 0,
            },
            '& .tabs > div': {
              pb: 0,
            },
          }}
        >
          <BackToProducts />
          <ProductHero
            product={data}
            productDisclaimer={listing.productDisclaimer}
            productDndLogo={listing.productDndLogo}
            productData={productData}
          />
          {productDetails?.id ? (
            <TabsWithMobileAccordions key={productDetails.id} {...productDetails} />
          ) : null}
          {Array.isArray(reimbursement)
            ? reimbursement.map((r) => (
              <ComponentSettings
                settings={r?.settings}
                entitySettingsValue={r?.entitySettingsValue}
                key={uniqueId('reimbursement--')}
              >
                <Suspense fallback={null}>
                  <Reimbursement {...r} />
                </Suspense>
              </ComponentSettings>
            ))
            : null}
        </Box>
      </Box>
      <Suspense fallback={null}>
        <Testimonials {...testimonials} sx={{}} />
      </Suspense>
      <Box
        sx={{
          display: {
            sm: hideOnMobile ? 'none' : 'block',
            md: 'block',
          }
        }}
      >
        <Suspense fallback={null}>
          <ProductGrid
            className="products__related"
            relatedProducts={relatedProducts}
            actionLabel={t('ecomm:product.button.buyNow')}
            container={
              !testimonials
                ? {
                  fullWidthBackground: true,
                  backgroundColor: colors.ultraLightBlue,
                }
                : null
            }
            type={'RelatedProducts-ProductGrid'}
            id={data?.entityId ?? data?.id}
          />
        </Suspense>
      </Box>
      {
        footerCta && (
          <GridContainer
            fullWidthBackground={!testimonials}
            backgroundColor={!testimonials ? colors.ultraLightBlue : null}
          >
            <ImageCta {...(footerCta || listing?.footerCta || {})} />
          </GridContainer>
        )
      }
    </Layout >
  )
}

export const query = graphql`
  query productPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "ecomm"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Product
