import React, { useEffect, useState } from 'react'
import useProductPrice from '@ggs/components/ecomm/ProductPrice/useProductPrice'
import { defaults, defaultsDeep, get, isEmpty } from 'lodash'
import { useSelector } from '@ggs/store'
import { getSearchParams } from '@ggs/utils'
import { navigate } from 'gatsby'
import { useCheckoutAnalytics } from '../../web/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { selectedProductOrDefault } from '../../web/components/hooks/ViewProductListObserver'

export const useNavToProductVariant = (productData) => {
  const {
    url,
    name,
    subtitle,
    includedItems = [],
    productOptions = [],
    productCategories,
    frequentlyBought,
    optionsLabel,
    optionsFooter,
    hasPricing,
    purchaseOption,
    activeOption,
    productImages = null,
    changeOption,
    skuPrice,
    productPrice,
    defaultOption,
  } = productData

  const [productSearchParams, setProductSearchParams] = useState(() => {
    try {
      const qs = getSearchParams()
      const poSearchParam = qs.get('po')
      return poSearchParam
    } catch {
      return null
    }
  })

  const navToProductVariant = ({ id = null } = {}) => {
    navigate('?po=' + id)
  }

  useEffect(() => {
    console.log({ activeOption, defaultOption, productSearchParams })
    if (!activeOption?.id && productSearchParams) {
      // this is early before active option is set= page load
      alert('loading...' + JSON.stringify({ defaultOption, productSearchParams }))
    } else if (activeOption?.id === defaultOption?.id && !productSearchParams) {
      // default option is the active option, no search params
    }
    if (activeOption?.id && activeOption.id !== defaultOption.id) {
      navToProductVariant({ id: activeOption.id })
    }
  }, [activeOption, defaultOption, productSearchParams])

  return {
    productSearchParams,
    navToProductVariant,
  }
}

export const useProductData = ({ data, location }) => {
  const {
    url,
    name,
    subtitle,
    stores,
    defaultOption,
    includedItems = [],
    productOptions = [],
    productUse,
    productCategories,
    frequentlyBought,
    whereToBuy,
    optionsLabel,
    optionsFooter,
    optionalProducts,
  } = data

  const { hasPricing } = useProductPrice({
    defaultOption,
    productOptions,
  })

  const defaultState = {
    currentOption: defaultOption,
    isDefault: true,
  }

  const [localState, setLocalState] = useState(() => {
    // @ts-ignore
    const qs = getSearchParams(location?.search)
    const variantId = Number(qs?.get('po'))
    const selectedVariant
      = variantId && variantId !== defaultOption.id
        ? productOptions.filter((i) => i.id === variantId)[0]
        : defaultOption

    /** @type {SearchState} */
    return {
      currentOption: selectedVariant ?? defaultOption,
      isDefault: !selectedVariant,
    }
  })

  const { fireSelectItem } = useCheckoutAnalytics()

  // console.log('Product Options: ', productOptions)
  // When non saleable options are available is fine to assume that default is offered for purchase action
  // but when there are multiples salable options we need for option selection to offer the purchase option.
  const purchaseOption = isEmpty(productOptions)
    ? localState.currentOption
    : localState.isDefault
    ? null
    : localState.currentOption

  /**
   * @param {ProductOption} selectedOption
   */
  const changeOption = (selectedOption) => {
    fireSelectItem({
      items: [selectedProductOrDefault({ ...data, activeOption: selectedOption })],
      item_list_name: `${data.type}--${data?.entityId}`,
      item_list_id: `${data.type}--${data?.entityId}`,
    })

    setLocalState(
      defaults(
        {
          // We always want product images. if the options don't have them for some reason and only the default does,
          // reuse the images.
          currentOption: defaultsDeep(selectedOption, {
            productImages: defaultOption?.productImages,
          }),
          isDefault: false,
        },
        defaultState
      )
    )
  }

  const productPrice = useProductPrice({
    currentOption: localState.currentOption,
  })
  const { skuPrice } = productPrice

  return {
    product: data,
    url,
    name,
    subtitle,
    stores,
    defaultOption,
    includedItems,
    productOptions,
    productUse,
    productCategories,
    frequentlyBought,
    whereToBuy,
    optionsLabel,
    optionsFooter,
    optionalProducts,
    localState,
    hasPricing,
    purchaseOption,
    changeOption,
    skuPrice,
    productPrice,
    activeOption: localState.currentOption,
    productImages: localState.currentOption?.productImages ?? null,
  }
}

export const useProductMetadata = () => {
  // const { clClient } = useCheckoutContext()
  // const { isAuth, customer } = useIsAuth()
  // const { optionalProducts } = product
  /**
   * @typedef {Object} LocalStateAction
   * @property {string} type
   */
  const commerce = useSelector((state) => state.commerce)
  const currentMarket = useSelector((state) => state.settings.currentMarket)
  const layoutMeta = useSelector(({ ui }) => ui?.layoutMeta)

  const { currentStore, canonicalUrls } = layoutMeta

  return {
    order: commerce?.order,
    canonicalUrls,
    currentMarket,
    currentStore,
  }
}

export const useProductOptionalItems = ({ product }) => {
  return useSelector((state) =>
    get(state.ui.localCart[String('/' + product.id)]?.optionalItems, String(product.id), [])
  )
}
